const getResponseUrl = 'https://app.getresponse.com/add_subscriber.html';

const consent = document.getElementById('webform_consent#BnR4_0');
const downloadBtn = document.getElementById('downloadBtn');
const email = document.getElementById('email');
const confirmation = document.querySelector('.confirmation');
const error = document.querySelector('.error');
const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
const buttonDisable = () => {
  if (consent.checked && emailRegex.test(email.value)) {
    downloadBtn.removeAttribute('disabled');
  } else {
    downloadBtn.setAttribute('disabled', '');
  }
};

consent.addEventListener('change', buttonDisable);
email.addEventListener('keyup', buttonDisable);

document.getElementById('signup-form')
  .addEventListener('submit', (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('email', email.value);
    formData.append('campaign_token', '8sILr');
    formData.append('start_day', '0');
    formData.append('webform[consent#BnR4-ver#SOtG]', 'true');

    fetch(getResponseUrl, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
      .then(() => {
        resetForm();
        showConfirmation();
      })
      .catch(() => showError());
  });

const resetForm = () => {
  email.value = '';
  consent.checked = '';
  confirmation.classList.add('hidden');
  error.classList.add('hidden');
}

const showConfirmation = () => {
  confirmation.classList.remove('hidden');
}

const showError = () => {
  error.classList.remove('hidden');
}
